import { environment } from '../../environments/environment';

export const GTM = environment.gtm;
export const RECAPTCHA_SITE_KEY = environment.recaptchaSiteKey;

export const HOST = environment.apiUrl;
export const EU_NUMBER = 'eu_number';
export const ROLE = 'role';
export const GRANT_TYPE = 'password';
export const CLIENT_ID = 1;
export const CLIENT_SECRET = '4jUCioJom16P0ucvW8jXME8zGvMsKs0MLXLrlJnm';

export const RVG_NUMBER = '100';
export const PERSONALNURSE = 'personalnurse';
export const WELCOME = 'welcome';

export const CURRENTUSER = 'currentuser';
export const HIDDENEMAIL = 'hiddenemail';

// api urls
export const URL_API = HOST + '/api';
export const URL_LOGIN = HOST + '/api/app-users/login';
export const URL_APPUSER = HOST + '/api/app-users';
export const URL_PROFILE = HOST + '/api/profile';
export const URL_REMOVE_ACCOUNT = HOST + '/api/delete';

export const URL_EVENT_SAVE = HOST + '/api/app-users/event';
export const URL_EVENT_REMOVE = HOST + '/api/app-users/remove-event';

export const URL_CSRF_TOKEN = HOST + '/sanctum/csrf-cookie';
export const URL_ADMINLOGIN = HOST + '/api/admin/login';
export const URL_ADMIN_LOGOUT = HOST + '/api/admin/logout';
export const URL_ADMINUSER = HOST + '/api/admin/auth';

export const URL_REGISTER = HOST + '/api/register';
export const URL_PASSWORD_RESET = HOST + '/api/password/reset';
export const URL_PASSWORD_SET = HOST + '/api/set-password';

export const URL_BLOGPOSTS = HOST + '/api/blog-posts';
export const URL_BLOGPOSTS_CATEGORIES = HOST + '/api/blog-posts/categories';
export const URL_BLOGPOSTS_CATEGORY = HOST + '/api/blog-posts/category/';
export const URL_BLOGPOSTS_TAGS = HOST + '/api/blog-posts/tags';

export const URL_IN_DE_AANVAL = HOST + '/api/in-de-aanval';

export const URL_SPEAKER = HOST + '/api/speaker';

export const URL_STATEMENT = HOST + '/api/statement';

export const URL_MEDICATION = HOST + '/api/medications';
export const URL_MEDICATION_CHECK = HOST + '/api/medications-check';
export const URL_EU_NUMBER_CHECK = HOST + '/api/eu-number-check';
export const URL_DOSAGEFORMS = HOST + '/api/dosage-forms';
export const URL_PERSONAL_MEDICATION = HOST + '/api/personal-medication';
export const URL_TRIGGERS = HOST + '/api/triggers';
export const URL_REGISTRATION = HOST + '/api/registration';
export const URL_REGISTRATION_DELETE = HOST + '/api/registration/delete';

export const URL_NURSE = HOST + '/api/nurses';

export const URL_DOCTOR = HOST + '/api/doctors';
export const URL_DOCTORSPECIALTY = HOST + '/api/doctors-specialty';

// Download option
export const URL_DOWNLOAD_PDF = HOST + '/api/pdf';

// Events
export const URL_EVENTS = HOST + '/api/events';

// About Pages
export const URL_ABOUTPAGES = HOST + '/api/about-pages';

// FAQ
export const URL_FAQS = HOST + '/api/faqs';
export const URL_FAQS_CATEGORIES = HOST + '/api/faqs/categories';

// Tips
export const URL_TIPS = HOST + '/api/tips';

// Migraine pages
export const URL_MIGRAINEPAGES = HOST + '/api/migraine-pages';

// Migraine pages
export const URL_MIGRAINEAPPPAGES = HOST + '/api/migraine-app-pages';

// Ajovy Pages
export const URL_AJOVYPAGES = HOST + '/api/ajovy-pages';

// About Pages
export const URL_CONTENTPAGES = HOST + '/api/content-pages';

// Expert pages
export const URL_EXPERTPAGES = HOST + '/api/expert-pages';

// Audio
export const URL_PODCASTS = HOST + '/api/podcasts';
export const URL_MUSIC = HOST + '/api/music';

export const URL_UPLOAD = HOST + '/api/fileupload';

// Download
export const URL_DOWNLOAD = HOST + '/api/downloads';

// Conversation aid
export const URL_CONVERSATION_AID = HOST + '/api/conversation-aids';

// Contact
export const URL_CONTACT = HOST + '/api/contact';

// Materials
export const URL_MATERIALS = HOST + '/api/materials';

// Notifications
export const URL_NOTIFICATIONS = HOST + '/api/notifications';

// users
export const URL_USERS = HOST + '/api/users';

//search
export const URL_SEARCH = HOST + '/api/search';

//Article
export const URL_ARTICLE = HOST + '/api/articles';

// Related pages
export const URL_RELATED = HOST + '/api/related';

// Situation check
export const URL_SITUATIONCHECK_DOWNLOAD = HOST + '/api/situation-check/download';
export const URL_SITUATIONCHECK_EMAIL = HOST + '/api/situation-check/email';
export const URL_SITUATIONCHECK_ARTICLE = HOST + '/api/situation-check/articles';

export const WASAJOVY = 'wasajovy';

export const IOS_LINK =
  'https://apps.apple.com/nl/app/migraineapp/id1500741266';
export const ANDROID_LINK =
  'https://play.google.com/store/apps/details?id=com.tevapharm.migraineapp';

// Appsflyer links to the App and Play Store.
// These variables are created in GTM and can be used in the website.
declare global {
  var AF_SMART_SCRIPT_RESULT: { clickURL: string; };
  var AF_SMART_SCRIPT_RESULT_ANDROID: { clickURL: string; };
  var AF_SMART_SCRIPT_RESULT_IOS: { clickURL: string; };
}

if (typeof window === 'undefined') {
  global.AF_SMART_SCRIPT_RESULT = undefined;
  global.AF_SMART_SCRIPT_RESULT_ANDROID = undefined;
  global.AF_SMART_SCRIPT_RESULT_IOS = undefined;
} else {
  window.AF_SMART_SCRIPT_RESULT = undefined;
  window.AF_SMART_SCRIPT_RESULT_ANDROID = undefined;
  window.AF_SMART_SCRIPT_RESULT_IOS = undefined;
}
