<div class="flex flex-col justify-between w-full px-3 pt-6 pb-24 mt-12 text-gray-600 sm:px-8 md:flex-row">
  <div>
    <p class="text-sm">MIG-NL-NP-01060</p>
    <p class="text-sm">
      &copy; Copyright {{ year }}
      <a routerLink="/contact" class="underline hover:no-underline focus:no-underline">Contact</a>
      /
      <a routerLink="/privacy-verklaring" class="underline hover:no-underline focus:no-underline">Privacy</a>
      /
      <a routerLink="/cookie-kennisgeving" target="_blank"
        class="underline hover:no-underline focus:no-underline">Cookie kennisgeving</a>
      /
      <a routerLink="/algemene-voorwaarden" class="underline hover:no-underline focus:no-underline">Algemene
        voorwaarden</a>
    </p>
  </div>
  <div class="flex justify-center mt-8 shrink-0 md:mt-0 md:justify-none">
    <div *ngIf="hasEuNumber$ | async" class="h-16 ml-0 mr-12 md:ml-12 md:mr-0">
      <picture class="w-auto h-auto">
        <source srcset="/assets/images/logo_ajovy.webp" type="image/webp" />
        <img loading="lazy" src="/assets/images/logo_ajovy.png" class="h-full" height="64" width="120" alt="AJOVY logo" />
      </picture>
    </div>
    <a href="https://www.teva.nl?utm_source=care4migraine.nl&utm_medium=logo&utm_campaign=Klikoplogo" target="_blank"
      rel="noopener" class="h-16 px-8 py-4 bg-white rounded-lg shadow md:ml-12" style="border-radius: 2rem">
      <img loading="lazy" src="/assets/images/logo-teva.svg" alt="Teva Nederland" class="h-full" height="32" width="90" />
    </a>
  </div>
</div>